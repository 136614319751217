<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'ResponsePerson', title: 'Відповідальні особи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_response_person)"
        :modal="show_response_person_dialog"
        @updateItemModal="responsePersonUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список відповідальних осіб
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openResponsePersonCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити відповідальну особу</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="onResponsePersonItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon>
              <v-icon size="26" class="mr-2" color="success">
                mdi-format-list-numbered
              </v-icon>
            </template>
            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate }}
              </span>
            </template>
            <template v-slot:item.category="{ item }">
              <span>
                {{ getCategoryName(item.category) }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_RESPONSE_PERSON} from "@/store/actions/response_people";
import {categoriesSelect} from "@/utils/icons";

export default {
  props: ['selectable', 'model'],
  name: "PositionView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getResponsePeople'
    })
  },
  data() {
    return {
      categoriesSelect,
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'П.І.Б', value: 'full_name' },
        { text: 'Посада', value: 'position_name' },
        { text: 'Категорія', value: 'category' },
        { text: 'Створено', value: 'create_date' },
        { text: 'Автор', value: 'owner_name' },
      ],
      selected_response_person: {},
      show_response_person_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_RESPONSE_PERSON
    }),
    getCategoryName(category) {
      const find_obj = this.categoriesSelect.find(item => item.value === category)
      return find_obj?.text || ''
    },
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    responsePersonUpdateItemModal() {
      this.show_response_person_dialog = false
      this.selected_response_person = {}
    },
    openResponsePersonCreateDialog() {
      this.selected_response_person = {}
      this.show_response_person_dialog = true
    },
    onResponsePersonItemClick(payload) {
      this.selected_response_person = JSON.parse(JSON.stringify(payload))
      this.show_response_person_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>